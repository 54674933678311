
import moment from 'moment'
import axios from '@/services/axios'
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'
import { formatTime } from '@/utils/dateTime'
import { formatterDollars } from '@/utils/formatter'
import { useNotification } from '@/composables'
import { ElMessageBox } from 'element-plus'
import { updateHandler } from '@/services/updateHandler'
import useUserRepositories from '@/repositories/useUserRepositories'
import { getFileNameFromUrl, downloadFile } from '@/utils/file'
// import TicketDialog from './components/TicketDialog.vue'
import TicketDetailDialog from './components/TicketDetailDialog.vue'
import SelectSellerTeam from '@/components/common/SelectSellerTeam.vue'

export default defineComponent({
  components: {
    Pagination,
    sectionLayoutContent,
    // TicketDialog,
    TicketDetailDialog,
    SelectSellerTeam,
  },
  setup(props: any, context: any) {
    const breadcrumbs = ['Ticket', '']
    const baseResource = 'orders/ticket/all'
    const params = reactive({ page: 1, limit: 10 })
    const { documents, fetchData, total } = useFetchData(baseResource, params)
    const isLoading = ref(false)
    const balance = ref('0')
    const { success, error, info } = useNotification()

    const content = computed(() => {
      return { breadcrumbs }
    })

    const columns = [
      {
        key: 'id',
        title: 'Created',
        dataIndex: 'created_date',
        minWidth: 10,
      },
      {
        title: 'Order',
        dataIndex: 'subject',
        minWidth: 15,
      },
      {
        title: 'Seller Team',
        dataIndex: 'seller_team',
        minWidth: 10,
      },
      {
        title: 'Information',
        dataIndex: 'custom_fields',
        minWidth: 25,
      },
      {
        title: 'Status',
        dataIndex: 'is_read_ticket',
        minWidth: 7,
      },
      {
        title: 'Attachments',
        dataIndex: 'attachments',
        minWidth: 20,
      },
      // {
      //   title: 'Actions',
      //   dataIndex: 'action',
      //   minWidth: 8,
      // },
    ]

    const loadData = async () => {
      isLoading.value = true
      await fetchData()
      isLoading.value = false
    }

    onMounted(async () => {
      await loadData()
    })

    watch(params, loadData)

    const openDepositDialog = () => {
      //
      success('Comming soon! ^^')
    }

    const updateStatus = async (sellerTeamId: string, newStatus: string, depositId: string) => {
      if (sellerTeamId?.length === 0) {
        error('Not found Seller team !')
        return
      }
      if (depositId?.length === 0) {
        error('Not found Deposit Id !')
        return
      }

      let action = 'Approve'
      if (newStatus === 'rejected') {
        action = 'Reject'
      }

      const updateResource = `deposits/seller-teams/${sellerTeamId}/${depositId}`
      const message = `Are you sure to ${action} this record?`
      ElMessageBox.confirm(message)
        .then(async () => {
          const { update, isLoading } = updateHandler(updateResource, {
            status: newStatus,
          })
          isLoading.value = true
          const { status, data } = await update()
          if (status === 200 || status === 201) {
            success('Transaction confirmed')
            loadData()
          } else {
            error(data.message)
          }
        })
        .catch(() => {
          info('Cancel action!')
        })
    }

    const detectTypeOfStatus = (status: string) => {
      let type = 'info'
      if (status) {
        if (status === 'approved') {
          type = 'success'
        } else if (status === 'pending') {
          type = 'warning'
        } else if (status === 'rejected') {
          type = 'danger'
        }
      }
      return type
    }

    const ticketDetaiDialog = ref<InstanceType<typeof TicketDetailDialog>>()
    const onViewDetailTicket = (id: string) => {
      ticketDetaiDialog.value?.toggle(id)
    }

    return {
      moment,
      balance,
      documents,
      params,
      total,
      content,
      columns,
      isLoading,
      formatTime,
      formatterDollars,
      detectTypeOfStatus,
      updateStatus,
      loadData,
      openDepositDialog,
      getFileNameFromUrl,
      downloadFile,
      ticketDetaiDialog,
      onViewDetailTicket,
    }
  },
})
